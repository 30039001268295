import React, { Component } from "react";
import "./MenuList.css";
import NewOne from "../../images/menus/folsommenu.jpeg";
import NewTwo from "../../images/menus/new2.jpg";
import Two from "../../images/menus/midtown-oct5.jpg";
import Happy from "../../images/menus/happy-4.jpg";
import Three from "../../images/menus/folsomdrinks.jpg";
import M from "materialize-css";

export default class MenuList3 extends Component {
  componentDidMount() {
    M.Tabs.init(this.Tabs, {
      swipeable: false,
    });
  }

  render() {
    return (
      <div className="menu-list">
        {/* <ReactTooltip type="dark" effect="solid" /> */}
        <div id="index-banner" className="header-menu">
          <div className="header-title">
            <br />
            <h1 className="center">Menus</h1>
          </div>
        </div>

        <div className="container next">
          <div className="row">
            <ul
              ref={(Tabs) => {
                this.Tabs = Tabs;
              }}
              className="tabs"
            >
              <li className="tab col s4 m4">
                <a data-tip="Menu (Folsom)" className="active" href="#tab__one">
                  Menu
                </a>
              </li>
              {/* <li className="tab col s4 m4">
                <a data-tip="Happy Hour" href="#tab__three">
                  Happy Hour
                </a>
              </li> */}
              <li className="tab col s4 m4">
                <a data-tip="Drinks" href="#tab__four">
                  Drinks
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div id="menu">
          <div className="menu-tabs">
            <div
              style={{ display: "flex !important", justifyContent: "center" }}
              id="tab__one"
              className="col s12 flex"
            >
              <img
                width="auto"
                height="auto"
                className="responsive-img"
                src={NewOne}
                alt="menu"
              />
              {/* <img
                width="auto"
                height="auto"
                className="responsive-img"
                src={NewTwo}
                alt="menu"
              /> */}
            </div>

            {/* <div
              style={{ display: "flex !important", justifyContent: "center" }}
              id="tab__three"
              className="col s12 flex"
            >
              <img
                width="auto"
                height="auto"
                className="responsive-img"
                src={Happy}
                alt="happy hour"
              />
            </div> */}

            <div
              style={{ display: "flex !important", justifyContent: "center" }}
              id="tab__four"
              className="col s12 flex"
            >
              <img
                width="auto"
                height="auto"
                className="responsive-img"
                src={Three}
                alt="cocktails"
              />
            </div>
          </div>
        </div>

        <div className="next-section"></div>
      </div>
    );
  }
}
