import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/pages/Landing";
import About from "./components/pages/About";
import Menu from "./components/pages/Menu";
import Natomas from "./components/pages/NatomasPage";
import Folsom from "./components/pages/Folsom";
import Midtown from "./components/pages/Midtown";
import Careers from "./components/pages/Careers";
import Contact from "./components/pages/Contact";
import Privacy from "./components/pages/Privacy";
import EmailList from "./components/pages/EmailList";
import Success from "./components/pages/201";
import Gallery from "./components/pages/Gallery";
import NotFound from "./components/pages/NotFound";
import UserForm from "./components/UserForm";
import Catering from "./components/pages/Catering";

export default () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/about" component={About} />
    <Route path="/menu" component={Menu} />
    <Route path="/midtown" component={Midtown} />
    <Route path="/natomas" component={Natomas} />
    <Route path="/folsom" component={Folsom} />
    <Route path="/Gallery" component={Gallery} />
    <Route path="/contact" component={Contact} />
    <Route path="/catering" component={Catering} />
    <Route path="/careers" component={Careers} />
    <Route path="/privacy" component={Privacy} />
    <Route path="/promotions" component={EmailList} />
    <Route path="/thank-you" component={Success} />
    <Route path="/user-form" component={UserForm} />
    <Route path="*" component={NotFound} />
  </Switch>
);
