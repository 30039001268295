import React, { Component } from "react";
import "./NavOne.css";
import $ from "jquery";
import "materialize-css/dist/css/materialize.min.css";
import logo from "../../../images/s-a.png";
import { Link } from "react-router-dom";
import Popup from "../../Popup/Popup";
import Reservation from "../../Popup/Reservation";
import PopupTwo from "../../Popup/PopupTwo";
import Pop from "../../Popup/Pop";

class Nav extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    var scroll = $(window).scrollTop();
    var objectSelect = $(".next-section");
    // .offset() retrieves current position of element relative to document
    var objectPosition = objectSelect.offset().top;

    if (scroll > objectPosition) {
      $(".nav-appear").css("top", "-18px");
    } else {
      $(".nav-appear").css("top", "-160px");
    }
  }

  render() {
    return (
      <div className="nav-one">
        <nav className="nav-appear">
          <div className="logo-section">
            <div className="row">
              <div className="col m5" style={{ display: "flex" }}>
                <div className="social-media">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/saigonalleysac"
                        target="_blank"
                        without="true"
                        rel="noopener noreferrer"
                      >
                        <p style={{ display: "none" }}>facebook</p>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/saigonalley.sac/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                        without="true"
                        rel="noopener noreferrer"
                      >
                        <p style={{ display: "none" }}>instagram</p>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.yelp.com/biz/saigon-alley-kitchen-bar-sacramento"
                        target="_blank"
                        without="true"
                        rel="noopener noreferrer"
                      >
                        <p style={{ display: "none" }}>yelp</p>
                        <i className="fab fa-yelp"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <Pop />
              </div>
              <div className="col m2">
                <Link to="/">
                  <img
                    width="auto"
                    height="auto"
                    className="logo-img"
                    src={logo}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col m5">
                <Popup />
                <PopupTwo />
              </div>
            </div>

            <div className="menu-section">
              <div className="col m4"></div>
              <div className="nav-menu col m4 shift">
                <Link to="/midtown">MIDTOWN</Link>
                <Link to="/natomas">NATOMAS</Link>
                <Link to="/folsom">FOLSOM</Link>
                <Link to="/catering">CATERING ORDERS</Link>
                <Link to="/gallery">GALLERY</Link>

                <Link to="/careers">CAREERS</Link>

                <Link to="/contact">CONTACT</Link>
                {/* <Reservation noStyle /> */}
              </div>
              <div className="col m4"></div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Nav;
